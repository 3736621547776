import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../styles.css";
const RefundComponent = () => {
  return (
    <div className="container px-4 pt-5">
      <div>
        <p className="title-heading">Transcil Refund Policy</p>
      </div>
      <div>
        <p>
          We hope that every trip you book through the Transcil Sustainable
          Services Private Limited goes without issue but we understand that
          sometimes things happen. If a trip you take does not meet your
          expectations, you can share your feedback by rating your driver in the
          app; and if you believe there was an error with your price or a more
          serious issue occurred on your trip, you can report it to our Support
          team. In some cases, your report may be covered by our Rider Refund
          Policy.
        </p>
        <br />
        <p>
          We want you to know what to expect when you report issues, so we are
          sharing examples of when you may be eligible to receive a full or
          partial refund of the price you paid. If you have an issue on a trip,
          let us know within 30 days. All requests will be evaluated at
          Transcil's sole discretion, and on a case-by-case basis.
        </p>
        <br />
        <p>
          As a reminder, Transcil Sustainable Services Private Limited is a
          marketplace that connects users to complete trips booked by a rider
          and accepted by a driver within the app. As a rider, you own the
          decision to take a trip or not. Transcil provides information to
          riders prior starting a trip, such as:
        </p>
        <br />
        <ul>
          <li>
            Pricing and other estimates in app like an upfront price and
            estimated time of arrival
          </li>
          <li>
            Vehicle and driver details like license plate, driver name, and
            vehicle type
          </li>
        </ul>
        <br />
        <p>
          As a rider, it’s your responsibility to check the information provided
          is accurate and meets your expectations of quality. If you decide to
          take a trip, you are accepting the terms of service and are taking
          responsibility for the successful payment of that trip.
        </p>
        <br />
        <p>
          <strong>
            When am I eligible to receive a full or partial refund?
          </strong>
        </p>
        <br />
        <p>
          Examples of when you may be eligible to receive a full or partial
          refund include:
        </p>
        <ul>
          <li>
            A fare or fee was charged in error or your trip experienced a
            technical issue, like an eligible promotion not applying correctly
          </li>
          <li>
            Your trip price was significantly higher than the estimate you were
            shown in the app prior to booking, and it was not due to added
            stops, or a changed destination
          </li>
          <li>
            Your trip was not completed within a reasonable walking distance to
            your requested destination
          </li>
          <li>
            You or the intended rider did not take the trip or there are other
            fraud concerns
          </li>
        </ul>
        <br />
        <p>
          <strong>
            When am I not eligible to receive a full or partial refund?
          </strong>
        </p>
        <br />
        <p>
          Examples of when you will not be eligible to receive a full or partial
          refund include, but are not limited to:
        </p>
        <ul>
          <li>
            An issue related to the vehicle quality or the type of vehicle
            dispatched for your trip
            <br />
            Tip: You can use Transcil’s 2-way rating system or contact customer
            support to help make drivers aware of issues with their vehicle
          </li>
          <li>
            An issue with a driver not meeting your quality expectations
            <br />
            Tip: You can use Transcil’s 2-way rating system or contact customer
            support to provide drivers with feedback about their service
          </li>
          <li>
            An issue that was caused by user error, like requesting the wrong
            type of vehicle
          </li>
          <li>
            An on-trip delay or on-trip routing issue due to uncontrollable
            events (e.g. traffic, construction) or a change in price due to
            adding or removing stops or updating your intended destination after
            your trip began
          </li>
        </ul>
        <br />
        <p>
          Note that any safety-related incidents are handled by a specialized
          team and can be reported to us at help.transcil.com.
        </p>
        <br />
        <p>
          <strong>Resolving Your Request</strong>
        </p>
        <br />
        <p>
          You can submit a request for a refund or a general complaint about
          your ride to Transcil’s customer service team at help.transcil.com or
          in the app within 30 days of encountering an issue. If we determine
          you are eligible, you should expect any refunds to be returned to your
          original payment method within 3-5 business days, depending on your
          bank.
        </p>
        <br />
        <p>
          <strong>False Reports</strong>
        </p>
        <br />
        <p>
          We investigate each report. Providing inaccurate information about
          your trip experience when disputing fees or charges violates Transcil
          Sustainable Services Pvt. Ltd Community Guidelines and may result in
          your request being denied and/or your account being deactivated.
        </p>
      </div>
    </div>
  );
};

export default RefundComponent;
