import React from "react";
import styles from "./landingComponent.module.css";
import sideImage from "./../../assets/mobile.png";
import playStoreIcon from "./../../assets/play-store.png";
import appleIcon from "./../../assets/apple-icon.png";
import bikeImage from "./../../assets/transcil.png";
import "bootstrap/dist/css/bootstrap.min.css";

const LandingComponent = () => {
  return (
    <div>
      <div className={styles.landingContainer}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 text-center text-md-left">
              <div className={styles.content}>
                <h1>Your Smooth Ride, Just a Tap Away</h1>
                <div className={styles.buttonContainer}>
                  <a
                    href="https://play.google.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.downloadButton}
                  >
                    <img src={playStoreIcon} alt="Download on Google Play" />
                    Download on Google Play
                  </a>
                  <a
                    href="https://www.apple.com/app-store/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.downloadButton}
                  >
                    <img src={appleIcon} alt="Download on the App Store" />
                    Download on the App Store
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center pt-5">
              <div className={styles.sideImage}>
                <img src={sideImage} alt="Side" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="intro2 row p-5 m-0 d-flex align-items-center justify-content-center text-center">
        <div className="img2 col-lg-6 col-md-6 col-sm-12">
          <img
            src={bikeImage}
            alt="Transcil Bike"
            className="img-fluid"
            style={{ width: "50%", height: "auto" }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <h3>Transcil E-Bike</h3>
          <p className="text-sm-start">
            Get affordable bike rides at your doorstep. Skip the crowd and zip
            through traffic with Transcil.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingComponent;
