import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppRoutes from "./components/routes";
import HeaderComponent from "./components/header/headerComponent";
import FooterComponent from "./components/footer/footerComponent";

function App() {
  return (
    <div>
      <Router>
        <HeaderComponent></HeaderComponent>
        <AppRoutes />
        <FooterComponent></FooterComponent>
      </Router>
    </div>
  );
}

export default App;
