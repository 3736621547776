import React from "react";
import { Link } from "react-router-dom";
import styles from "./headerComponent.module.css";
import logo from "../../assets/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";

const HeaderComponent = () => {
  return (
    <header className={`fixed-top ${styles.headerContainer}`}>
      <div className="container-fluid">
        <div className="d-flex justify-content-center align-items-center">
          {/* Logo linking to home */}
          <div className={styles.logoContainer}>
            <Link to="/">
              {" "}
              <img src={logo} alt="Logo" className="img-fluid" />
            </Link>
          </div>

          {/* Title linking to About Us */}
          <div className={styles.title}>
            <Link to="/about-us" className={styles.link}>
              About Us
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
