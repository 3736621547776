import React from "react";
import "./footerComponent.module.css";
import styles from "./footerComponent.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row justify-content-center pb-4">
          <div className="col-12 col-md-auto col-lg-auto text-center">
            <Link to="/terms-and-conditions" className={styles.font}>
              Terms and Conditions
            </Link>
          </div>
          <div className="col-12 col-md-3 col-lg-auto text-center">
            <Link to="/about-us" className={styles.font}>
              About Us
            </Link>
          </div>
          <div className="col-12 col-md-auto col-lg-auto text-center">
            <Link to="/refund-policy" className={styles.font}>
              Refund Policy
            </Link>
          </div>
          <div className="col-12 col-md-auto col-lg-auto text-center">
            <Link to="/privacy-policy" className={styles.font}>
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className="row justify-content-center pb-3">
          <div className="col-12 col-md-auto  text-center">
            <p>
              © {currentYear} Transcil Sustainable Services Private Limited. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
