import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../styles.css";
const AboutUsComponent = () => {
  return (
    <div className="container px-4 pt-5">
      <div>
        <p className="title-heading">About Transcil</p>
      </div>
      <div className="text-center mb-4">
        <img
          src={require("./../../assets/about-us.png")}
          alt="about-us"
          className="img-fluid"
          style={{ width: "300px" }}
        />
      </div>
      <p className="text-center fw-bold display-5 mb-4">Sustainable Rides</p>
      <p className="text-justify mb-4 para-content">
        At Transcil Sustainable Services Private Limited, we're passionate about
        creating a cleaner, greener future for urban transportation. Our mission
        is to provide sustainable and convenient electric motorcycle
        ride-sharing services that reduce carbon emissions and improve air
        quality.
      </p>
      <p className="text-justify mb-4 para-content">
        Our fleet of electric motorcycles is equipped with the latest technology
        to ensure a safe and enjoyable riding experience. We prioritize rider
        safety and comfort, and our vehicles are regularly maintained and
        inspected to meet the highest standards.
      </p>
      <p className="text-justify mb-4 para-content">
        By choosing Transcil Sustainable Services Private Limited, you're not
        only getting a convenient and affordable ride but also contributing to a
        more sustainable planet. Our electric motorcycles produce zero
        emissions, making them a greener alternative to traditional vehicles.
      </p>
    </div>
  );
};

export default AboutUsComponent;
