import React from "react";
import { Routes, Route } from "react-router-dom";
import TermsComponent from "../terms/termsComponent";
import AboutUsComponent from "../about-us/AboutUsComponent";
import RefundComponent from "../refund/refundPolicyComponent";
import LandingComponent from "../landingPage/landingComponent";
import PrivacyComponent from "../privacy/privacyComponent";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/terms-and-conditions" element={<TermsComponent/>} />
      <Route path="/about-us" element={<AboutUsComponent />} />
      <Route path="/refund-policy" element={<RefundComponent />} />
       <Route path="/privacy-policy" element={<PrivacyComponent />} />
       <Route path="/" element={<LandingComponent />} />
    </Routes>
  );
};

export default AppRoutes;